import React, { useRef, useState } from "react";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import { useDispatch } from "react-redux";
import { reportPost } from "../../redux/actions/report";
import { report } from "../../api/report";
import { Modal } from "react-bootstrap";

const ReportButton = (props) => {
    const dispatch = useDispatch();
    const { postId } = props;
    const [showModal, setShowModal] = useState(false);
    const notesRef = useRef();

    const onClick = () => {
        setShowModal(true);
    };

    const onSubmit = () => {
        dispatch(
            report({
                param_path: `post/${postId}`,
                method: "POST",
                data: {
                    model_id: postId,
                    model_name: "post",
                    reason: notesRef.current?.value || "",
                },
            })
        )
            .then((response) => {
                if (response.success) {
                    dispatch(reportPost(postId));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <Modal
                centered={true}
                size={props.size || `lg`}
                id="submit-modal"
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop="static"
            >
                <Modal.Header className="p-3 border-bottom">
                    <h4 className="modal-title">Report Content</h4>
                    <Button
                        className="close"
                        type="link"
                        onClick={() => setShowModal(false)}
                    >
                        x
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="">
                            Please specify what is wrong in this content?
                        </label>
                        <textarea
                            ref={notesRef}
                            className="form-control"
                            rows="3"
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            type="button"
                            className="btn btn-sm btn-danger mr-2"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-sm"
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Button
                className={`btn-sm mr-1 rounded-pill btn-danger`}
                onClick={onClick}
                type="button"
            >
                <Icon name="shield" /> Report
            </Button>
        </>
    );
};

export default ReportButton;
