import React from "react";
import { PLACEHOLDER } from "../../helpers/constant";

const SquareImage = React.memo((props) => {
    const onLoad = async () => { };

    return <img className={props.className} src={props.src || PLACEHOLDER} alt={props.alt || "#"} style={{ width: props.width, height: props.height, objectFit: props.objectFit || "inherit" }} onLoad={onLoad} />;
});

export default SquareImage;
