import React, { useRef, useEffect, useState } from "react";
import {
    StackedCarousel,
    ResponsiveContainer,
} from "react-stacked-center-carousel";
import "component/VideoCarousel/video_carousel.css";
import Video from "../../component/Video/Video";
import moment from "moment";
import { DATE_FORMAT, PLACEHOLDER } from "helpers/constant";
import RoundImage from "../../component/Image/RoundImage";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { posts as postsApi } from "../../api/posts";
import ShareModal from "../../component/ShareModal/ShareModal";
import Icon from "../../component/Icon/Icon";
import MinimalCarousel from "../../component/MinimalCarousel/MinimalCarousel";

const PostVideoCarousel = React.memo((props) => {
    const ref = useRef();

    if (props.posts?.length <= 0) {
        return null;
    }

    return (
        <>
            <div className="twitch d-lg-block d-md-block d-sm-none">
                <div style={{ width: "100%", position: "relative" }}>
                    <ResponsiveContainer
                        carouselRef={ref}
                        render={(width, carouselRef) => {
                            return (
                                <StackedCarousel
                                    ref={carouselRef}
                                    slideComponent={Slide}
                                    slideWidth={500}
                                    carouselWidth={width}
                                    data={props.posts}
                                    maxVisibleSlide={5}
                                    disableSwipe
                                    customScales={[1, 0.85, 0.7, 0.55]}
                                    transitionTime={450}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div className="mobile-featured-post d-lg-none d-md-none d-sm-block my-3">
                <div className="heading-section text-center">
                    <h5>
                        Featured Post
                    </h5>
                    <div className="center-ball-separator-hr mb-4"></div>
                </div>
                <MinimalCarousel desktopItemsToShow={3} tabletItemsToShow={3} mblItemsToShow={2} autoPlay={false} centerMode={false} itemClass={`mx-2`}>
                    {
                        props.posts.map((cp) =>
                        (
                            <div className="video-item mb-2 border p-0 position-relative" key={cp.id} style={{ borderRadius: "10px" }}>
                                <div className="item">
                                    <Video
                                        poster={cp.thumbnail}
                                        src={cp.uri} style={{ width: "100%", height: "150px", borderRadius: "10px" }} />
                                </div>
                                <div className="position-absolute" style={{ width: "20%", top: "5px", left: "5px" }}>
                                    <div className="video-by d-inline-block mb-2">
                                    <Link className={`text-capitalize text-info`} to={cp?.nature == "club" ? `/clubs/${cp?.club?.id}` : `/profile/${cp?.created_by?.id}`}>
                                        <RoundImage src={cp?.created_by?.profile_pic || PLACEHOLDER} alt={cp?.created_by?.name} size={`25px`} className={`mr-2`} />
                                    </Link>
                                    </div>
                                    <div className="video-stats d-none ">
                                        {/* likes stats */}
                                        <div className={`text-light mb-1`} style={{ fontSize: "10px" }}>
                                            <Icon className={`mr-1`} name={`like`} />
                                            <span>
                                                {cp.no_likes}
                                            </span>
                                        </div>
                                        {/* views stats */}
                                        <div className={`text-light mb-1`} style={{ fontSize: "10px" }}>
                                            <Icon className={`mr-1`} name={`eye-alt`} />
                                            <span>
                                                {cp.no_views}
                                            </span>
                                        </div>
                                        {/* shares stats */}
                                        <div className={`text-light mb-1`} style={{ fontSize: "10px" }}>

                                            <Icon className={`mr-1`} name={`share`} />
                                            <span>
                                                {cp.no_shares}
                                            </span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        )
                        )
                    }
                </MinimalCarousel>
            </div>
        </>
    );
});

export default PostVideoCarousel;

export const Slide = React.memo(function (props) {
    const dispatch = useDispatch();
    const { data, dataIndex, isCenterSlide, swipeTo, slideIndex } = props;
    const [loadDelay, setLoadDelay] = useState();
    const [posts, setPosts] = useState(props.data);
    const [loaded, setLoaded] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);

    useEffect(() => {
        if (isCenterSlide) {
            setLoadDelay(setTimeout(() => setLoaded(true), 500));
        } else {
            setLoaded(false);
        }
    }, [isCenterSlide]);

    useEffect(
        () => () => {
            clearTimeout(loadDelay);
        },
        [loaded]
    );

    const post = posts[dataIndex] || {};

    const [liked, setLiked] = useState(post.is_liked);

    const likePost = (e) => {
        dispatch(
            postsApi({
                param_path: `${post.id}/like`,
                method: "POST",
                data: {
                    boosted: true,
                },
            })
        )
            .then((response) => {
                if (response.success) {
                    setPosts((p) => {
                        return p.map((p) => {
                            if (p.id === post.id) {
                                return response.data;
                            }
                            return p;
                        });
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <>
            <ShareModal
                show={showShareModal}
                handleClose={() => setShowShareModal(false)}
                post={post}
                is_boosted={post?.is_boosted}
            />
            <div
                className="twitch-card"
                draggable={false}
                style={{
                    borderRadius: "25px",
                }}
            >
                <div
                    className={`cover fill ${isCenterSlide && loaded ? "off" : "on"
                        }`}
                >
                    <div
                        className="card-overlay fill"
                        onClick={() => {
                            if (!isCenterSlide) swipeTo(slideIndex);
                        }}
                    />
                    <img className="cover-image fill" src={post?.thumbnail} />
                </div>
                {loaded && (
                    <div style={{ width: "100%" }} className="detail fill">
                        {post?.is_boosted == true ? <span className="badge badge-warning" style={{ position: "absolute", top: "10px", left: "10px" }}>Sponsored</span> : ""}
                        <Video
                            // className={`${post?.status == 'unsafe' ? "unsafe-post" : ""} `}
                            poster={post?.thumbnail}
                            style={{ width: "90%" }}
                            src={post?.uri}
                            status={post?.status}
                            postId={post?.id}
                            hasWatched={post?.has_viewed}
                            is_boosted={post?.is_boosted}
                            autoPlay={`${post?.status == 'unsafe' ? "false" : "true"} `}
                            muted
                        />
                        <div
                            className="discription video-sidebar"
                            style={{ width: "10%" }}
                        >
                            <div
                                className="p-2"
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                <div className="friend-info pt-2">
                                    <Link className={`text-capitalize text-info`} to={post?.nature == "club" ? `/clubs/${post?.club?.id}` : `/profile/${post?.created_by?.id}`}>
                                    <figure>
                                        <em></em>
                                        <RoundImage
                                            src={
                                                post?.created_by?.profile_pic ||
                                                PLACEHOLDER
                                            }
                                            alt={post?.created_by?.name}
                                            size="40px"
                                        />
                                    </figure>
                                    </Link>
                                    <div className="friend-name d-none">
                                        <ins>
                                            <Link
                                                to={`/profile/${post?.created_by?.id}`}
                                            >
                                                {post?.created_by?.name}
                                            </Link>
                                        </ins>
                                        <span>
                                            <i
                                                className={`icofont-${post?.is_public == 1
                                                    ? "globe"
                                                    : "lock"
                                                    } `}
                                            />{" "}
                                            published:{" "}
                                            {moment
                                                .utc(
                                                    post?.created_at,
                                                    DATE_FORMAT
                                                )
                                                .format("MMM, DD YYYY")}
                                        </span>
                                    </div>
                                    <div className="post-meta d-none">
                                        <p>{post?.content}</p>
                                    </div>
                                </div>
                            </div>

                            <p
                                className="text-center text-dark"
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                <span
                                    onClick={likePost}
                                    className={`${post.is_liked ? "text-primary" : ""
                                        }`}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="icofont-thumbs-up"></i>
                                </span>
                                <a>
                                    <span className="d-block">
                                        {post.no_likes}
                                    </span>
                                </a>
                            </p>

                            <p
                                className="text-center text-dark"
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                <span
                                    className={`${post.has_viewed ? "text-primary" : ""
                                        }`}
                                >
                                    <i className="icofont-eye-alt"></i>
                                </span>
                                <a>
                                    <span className="d-block">
                                        {post.no_views}
                                    </span>
                                </a>
                            </p>

                            <p
                                onClick={() => setShowShareModal((p) => !p)}
                                className="text-center text-dark"
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                <span
                                    className={`${post.has_shared ? "text-primary" : ""
                                        }`}
                                >
                                    <i className="icofont-share"></i>
                                </span>
                                <a>
                                    <span className="d-block">
                                        {post.no_shares}
                                    </span>
                                </a>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
});
