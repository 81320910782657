import Button from "../../component/Button/Button";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hunts as huntsApi } from "../../api/hunts";
import SendNotification from "../../helpers/notification";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import RoundImage from "../../component/Image/RoundImage";
import { BANNER, PLACEHOLDER } from "../../helpers/constant";
import { Link } from "react-router-dom";
import ConfirmModal from "../../component/ConfirmModal/ConfirmModal";
import CoverImage from "../../component/Image/CoverImage";
import SquareImage from "../../component/Image/SquareImage";
import styles from "./ComponentCss/newsfeedcard.module.css";
import ApplyJobModal from "../../component/ApplyJob/ApplyJobModal";

const UserJobCard = (props) => {
    const { job, onDelete, onUpdate } = props;
    const [hasApplied, setHasApplied] = useState(job.has_applied);
    const [appliedCount, setAppliedCount] = useState(job.applied_by);
    const auth_user = useSelector((store) => store.user.user);
    const [postActions, setPostActions] = useState([]);
    const history = useHistory();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmWithdraw, setShowConfirmWithdraw] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (job?.created_by?.id == auth_user?.id) {
            setPostActions((p) => [
                {
                    title: "Edit Hunt",
                    subText: "Edit your Hunt",
                    icon: "icofont-edit",
                    action: () => {
                        history.push(`/hunt/${job.id}/edit`);
                    },
                },
                {
                    title: job.status === "open" ? "Close" : "Open",
                    subText: `${job.status === "open" ? "Close" : "Open"} Hunt`,
                    icon: `icofont-${job.status === "open" ? "close" : "envelope-open"}`,
                    action: () => {
                        let status = job.status === "open" ? "close" : "open";
                        dispatch(
                            huntsApi({
                                param_path: `${job.id}/close`,
                                method: "POST",
                                data: { status },
                            }),
                        )
                            .then((response) => {
                                if (response.success) {
                                    onUpdate(response.data);
                                    toast.success(response.message);
                                }
                            })
                            .catch((e) => {
                                toast.error(e.response.data.message);
                            });
                    },
                },
                {
                    title: "Delete Hunt",
                    subText: "Delete your Hunt",
                    icon: "icofont-ui-delete",
                    action: () => {
                        setShowConfirm(true);
                        setConfirmMessage("Are you sure you want to delete this hunt?");
                    },
                },
            ]);
        }
    }, [dispatch, job, auth_user]);

    const deleteJob = () => {
        dispatch(
            huntsApi({
                method: "DELETE",
                param_path: `${job.id}`,
            }),
        )
            .then((response) => {
                if (response.success) {
                    onDelete(job.id);
                    toast.success(response.message);
                    setShowConfirm(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //apply job
    const applyJob = useCallback((job_id) => {
        dispatch(
            huntsApi({
                param_path: job_id + "/applicants",
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    setAppliedCount(hasApplied ? appliedCount - 1 : appliedCount + 1);
                    setHasApplied((p) => !p);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setShowConfirmWithdraw(false);
            })
            .catch((err) => {
                console.log(err);
            });
    });

    return (
        <div
            className="main-wraper"
            style={{
                position: "inherit",
            }}
        >
            <ConfirmModal showModal={showConfirm} title="Confirm" message={confirmMessage} onConfirm={deleteJob} onClose={() => setShowConfirm(false)} />
            <ConfirmModal
                showModal={showConfirmWithdraw}
                title="Confirm"
                message={"Are you sure you want to withdraw from this job?"}
                onConfirm={() => applyJob(job?.id)}
                onClose={() => setShowConfirmWithdraw(false)}
            />
            <div className="row">
                <div className="col-lg-7">
                    {/* img yaha aigi */}
                    <div className="position-relative" style={{ height: "100%" }}>
                        {/* <SquareImage className="rounded" src={BANNER} width={"100%"} height="250px" /> */}
                        <CoverImage className="rounded" bgrepeat="no-repeat" bgsize="cover" bgsrc={`url(${job?.image || BANNER})`} width="100%" height="300px" />
                        <div className={`${styles["badgess"]}`}>
                            <div className="partner">
                                <span className={`${styles["badge"]} badge-danger text-capitalize`}>{job?.job_type?.type_name}</span>
                            </div>
                            <div className="amount">
                                <span className={`${styles["badge"]} badge-info`}>
                                    ${job?.min_salary} - ${job?.max_salary}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="position-relative py-2" style={{ height: "100%" }}>
                        <div className="d-flex align-items-center">
                            <div className="user-img">
                                <RoundImage src={job.created_by.profile_pic || PLACEHOLDER} size="40px" />
                                {/* <div className="badges">
                                    sadsa
                                </div> */}
                            </div>
                            <div className={`user-info ml-2 flex-fill`}>
                                <div className={`d-block`}>
                                    <ins>
                                        <Link className={`text-capitalize`} to={`/profile/${job?.created_by?.id}`}>
                                            <span>{job.created_by?.name}</span>
                                            <br />
                                            <small className="text-muted">@{job.created_by?.username}</small>
                                        </Link>
                                    </ins>
                                </div>
                            </div>
                            <div className="item text-right">
                                <i className="icofont-briefcase job-clr d-block" style={{ fontSize: "40px" }}></i>
                                {!hasApplied && <span className={`badge ${job?.status == "open" ? "badge-success" : "badge-secondary"}`}>{job?.status == "open" ? "open" : "closed"}</span>}
                                {hasApplied && <span className={`badge badge-success`}>Applied</span>}
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                            <div className="desc w-100">
                                <div className="basic">
                                    <Link className="flex-fill" to={`/hunt/${job.id}`}>
                                        <p className="mb-0 text-truncate">Job Title: {job.job_title}</p>
                                        <p className="mb-0 text-truncate">Description: {job.job_description}</p>
                                    </Link>
                                    {job?.created_by?.id === auth_user?.id && (
                                        <div className="more-post-optns">
                                            <i className="feather feather-more-horizontal">
                                                <svg
                                                    className="feather feather-more-horizontal"
                                                    strokeLinejoin="round"
                                                    strokeLinecap="round"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    height={24}
                                                    width={24}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle r={1} cy={12} cx={12} />
                                                    <circle r={1} cy={12} cx={19} />
                                                    <circle r={1} cy={12} cx={5} />
                                                </svg>
                                            </i>
                                            <ul>
                                                {postActions.map((action, index) => (
                                                    <li key={index}>
                                                        <Button
                                                            onClick={action.action}
                                                            type="link"
                                                            style={{
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            <i className={action.icon}></i>
                                                            {action.title}
                                                            <span>{action.title}</span>
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="additional">
                                    <div className="participant-sec">
                                        <div className="participant-sec-left">
                                            <i className="icofont-users text-success"></i>
                                            <span className="ml-2">{appliedCount} Applicants</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {job?.created_by?.id !== auth_user?.id && (
                            <div className={`${styles["action-btns"]} text-right`}>
                                <ApplyJobModal job={job} />
                            </div>
                        )}
                    </div>

                    <div className="user-post d-none">
                        <div className="friend-info">
                            <Link to={`/profile/${job?.created_by?.id}`}>
                                <RoundImage src={job.created_by.profile_pic || PLACEHOLDER} size="40px" />
                                <div className="friend-name width-92">
                                    <ins className="text-capitalize">{job.created_by?.name}</ins>
                                    <span>@{job.created_by?.username}</span>
                                </div>
                            </Link>
                            <div className="more">
                                <i className="icofont-briefcase job-clr d-block" style={{ fontSize: "40px" }}></i>
                                <span className={`badge ${job?.status == "open" ? "badge-success" : "badge-secondary"}`}>{job?.status == "open" ? "open" : "closed"}</span>
                            </div>
                            <div className="post-meta">
                                <Link to={`/hunt/${job.id}`}>
                                    <p className="mb-0">Job Title: {job.job_title}</p>
                                    <p className="mb-0">Description: {job.job_description}</p>
                                </Link>
                                <div className="participant-sec">
                                    <div className="participant-sec-left">
                                        <i className="icofont-users"></i>
                                        <span className="ml-2">{appliedCount} Participants</span>
                                    </div>
                                </div>

                                <div className="we-video-info">
                                    <ul>
                                        <li>
                                            <span title="Participants" className="Recommend">
                                                <i className="icofont-paper-plane"></i>
                                                <span>{appliedCount}</span>
                                            </span>
                                        </li>
                                    </ul>

                                </div>

                                {job?.created_by?.id == auth_user?.id && (
                                    <div className="more">
                                        <div className="more-post-optns" style={{ float: "right" }}>
                                            <i className="feather feather-more-horizontal">
                                                <svg
                                                    className="feather feather-more-horizontal"
                                                    strokeLinejoin="round"
                                                    strokeLinecap="round"
                                                    strokeWidth={2}
                                                    stroke="currentColor"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    height={24}
                                                    width={24}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle r={1} cy={12} cx={12} />
                                                    <circle r={1} cy={12} cx={19} />
                                                    <circle r={1} cy={12} cx={5} />
                                                </svg>
                                            </i>
                                            <ul>
                                                {postActions.map((action, index) => (
                                                    <li key={index}>
                                                        <Button
                                                            onClick={action.action}
                                                            type="link"
                                                            style={{
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            <i className={action.icon}></i>
                                                            {action.title}
                                                            <span>{action.title}</span>
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UserJobCard;
