import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../component/Icon/Icon";
import { posts as postsApi } from "../../api/posts";
import { useDispatch, useSelector } from "react-redux";
import ShareModal from "../../component/ShareModal/ShareModal";
import Button from "../../component/Button/Button";
import styles from "./ComponentCss/newsfeedcard.module.css";
import BuyModal from "../../component/BuyModal/BuyModal";

const PostStats = (props) => {
    // const post = props.post;
    const history = useHistory();
    const dispatch = useDispatch();
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [post, setPost] = useState(props.post);
    const likeBtn = useRef();
    const [showShareModal, setShowShareModal] = useState(false);

    const onLike = async (e) => {
        e.preventDefault();
        likeBtn.current?.setLoader(true);
        try {
            const params = {
                method: "POST",
                param_path: `${post.id}/like`,
            };
            const response = await dispatch(postsApi(params));

            if (response.success) {
                setPost(response.data);
            }
        } catch (err) {
            console.log(err);
        }
        likeBtn.current?.setLoader(false);
    };

    const showLikes = () => {
        if (post.no_likes > 0) {
            history.push(`/all/likes/${post.id}`);
        }
    };

    const showViews = () => {
        if (post?.no_views > 0) {
            history.push(`/all/views/${post.id}`);
        }
    };

    // useEffect(() => {
    //     setPost(props.post);
    // }, post)

    return (
        <ul className="p-0 pt-1 mb-0 text-muted" style={{ fontSize: "16px", display: "flex", flexDirection: "column", gap: "10px"}}>
            {post.type != "challenge" && (
                <li className="mr-2 d-inline" type="button">
                    {/* <i className="icofont-like mr-1"></i> */}
                    {post.type != "challenge" && post?.uri && (
                        <Icon
                            name="like"
                            onClick={onLike}
                            className={post.is_liked ? "text-info mr-1" : "mr-1 text-dark"}
                        />
                    )}
                    <span onClick={showLikes}>{post.no_likes} Likes</span>
                </li>
            )}
            <li className="mr-2 d-inline" onClick={showViews} type="button">
                <i className="icofont-eye-alt mr-1 text-dark"></i>{post.no_views} Views
            </li>
            <li className="mr-2 d-inline" onClick={() => props.toggleComments()} type="button">
                {/* <i className="icofont-comment mr-1"></i> */}
                {props.post?.uri && (
                    <Icon name="comment" className={"mr-1 text-dark"}   />
                )}
                <span>{props.post.no_comments} Comments</span>
            </li>
            {/* <li className="mr-2 d-inline">
                <i className="icofont-share mr-1" style={{ fontSize: "14px" }}></i>{post.no_shares}
            </li> */}
            {/* {post.type == "challenge" && (
                <li className="mr-2 d-inline">
                    <i className="icofont-stamp mr-1"></i>{post.no_votes}
                </li>
            )} */}
            {post.type == "challenge" && (
                post?.challenge_status == "voting" &&
                post?.uri && (
                    <li className="mr-2 d-inline">
                        <Icon
                            name="stamp"
                            onClick={onLike}
                            className={
                                post.has_voted ? "text-info mr-1" : "mr-1  text-dark"
                            }
                        />
                    </li>
                )                
            )}
        </ul>
    );
};

export default PostStats;


const PostReaction = (props) => {
    const user = useSelector((store) => store.user.user);
    // Like post code and variables
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [post, setPost] = useState(props.post);
    const [showShareModal, setShowShareModal] = useState(false);


    return (
        <>
            <ShareModal
                post={post}
                show={showShareModal}
                handleClose={() => setShowShareModal(false)}
            />
            <div
                className={`${styles["stat-tools"]} stat-tools d-flex flex-wrap m-0`}
            >
                <div className={`mr-1 mt-1`}>
                    

                    {/* <Button
                        className={`btn-sm mr-1 rounded-pill btn-light`}
                        type="button"
                        onClick={() => setShowShareModal(true)}
                    >
                        <Icon name="share" /> Share
                    </Button> */}

                    {!post?.uri && (
                        <Button
                            className={`btn-sm mr-1 rounded-pill btn-light`}
                            onClick={() => {
                                setShowBuyModal(true);
                            }}
                            type="button"
                        >
                            <Icon name="dollar" /> Buy
                        </Button>
                    )}

                    {showBuyModal && (
                        <BuyModal
                            show={showBuyModal}
                            post={post}
                            update={(p) => {
                                setPost(p);
                                setShowBuyModal(false);
                                props.updatePost(p);
                            }}
                            hide={() => {
                                setShowBuyModal(false);
                            }}
                        />
                    )}
                </div>
                {/* <div className={`box mr-1 mt-1`}>
                    <Icon onClick={() => props.toggleComments()} wrapperclassName={`comment-to`} title="Comment" name="comment" />
                </div>
                <div className={`box mr-1 mt-1`}>
                    <Icon wrapperclassName={``} title="Share" name="share" onClick={() => setShowShareModal(true)} />
                </div> */}
            </div>
        </>
    );
};

export {PostReaction};