import React from 'react';
import { url } from '../../../node_modules/socket.io-client/build/cjs/url';

const CoverImage = React.memo((props) => {

    const onLoad = async () => {

    };

    if (props.image) {
        return (
            <div className={props.className} alt={props.alt || "#"}>
                <img src={props.bgsrc} style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }} />
                {props.children}
            </div>
        )
    }

    return (
        <div className={props.className} alt={props.alt || "#"} style={{ backgroundColor: props.bgcolor || null, backgroundBlendMode: props.bgblendmode || null, width: props.width, height: props.height, backgroundImage: props.bgsrc, backgroundRepeat: props.bgrepeat, backgroundSize: props.bgsize, backgroundPosition: props.bgposition }}>
            {props.children}
        </div>

    )
});

export default CoverImage;