import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button/Button";
const ConfirmModal = (props) => {
    const { showModal, onConfirm, title, message, onClose } = props;
    const btnYes = useRef();

    return (
        <Modal centered={props.centered || false} size={props.size || `lg`} id="submit-modal" show={showModal} onHide={() => onClose()} backdrop="static">
            <Modal.Header className="p-3 border-bottom">
                <h4 className="modal-title">{title}</h4>
                <Button className="close" type="link" onClick={() => onClose()}>
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button type="button" ref={btnYes} className="btn btn-primary" onClick={() => onConfirm(btnYes)}>
                    Yes
                </Button>
                <Button type="button" className="btn btn-secondary" onClick={() => onClose()}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
