import React, { useState, useEffect } from "react";
import { PLACEHOLDER } from "helpers/constant";
import { useDispatch } from "react-redux";
import { users as userApi } from "../api/users";
import { getSocket } from "../core/socket";
import { useSelector } from "react-redux";
import RoundImage from "../component/Image/RoundImage";
import { Link } from "react-router-dom";
import Button from "../component/Button/Button";
import styles from "../pages/Home/home.module.css";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const SuggestedUsersWidget = React.memo((props) => {
    const dispatch = useDispatch();

    const [suggestedUsers, setUsers] = useState(props.users);
    const auth_user = useSelector((store) => store.user.user);
    const followRefs = useRef(props.users.map(() => React.createRef()));
    const unfollowRefs = useRef(props.users.map(() => React.createRef()));
    const seeAll = props.seeAll || `/all/users`;
    const history = useHistory();

    const followUser = (user, ind) => {
        followRefs.current[ind]?.current?.showLoader(true);
        unfollowRefs.current[ind]?.current?.showLoader(true);
        dispatch(
            userApi({
                method: "POST",
                param_path: user?.id.toString() + "/follow",
            })
        )
            .then((response) => {
                if (response.success) {
                    followRefs.current[ind]?.current?.showLoader(false);
                    unfollowRefs.current[ind]?.current?.showLoader(false);
                    setUsers(
                        suggestedUsers.map((u) => {
                            if (u.id == user?.id) {
                                u.is_following = !u.is_following;
                                return u;
                            } else {
                                return u;
                            }
                        })
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        setUsers(props.users);
    }, [props.users]);

    const handleClick = (u) => {
        if (props.hideBtns) {
            history.push(`/hunts/${props.huntId}/${u.application_type == "special" ? "cv" : "resume"}/${u.cv_id}`);
        } else {
            history.push(`/profile/${u.id}`);
        }
    };

    const renderList = () => {
        console.log(suggestedUsers);
        return suggestedUsers?.map((u, i) => {
            return (
                <li key={u.id}>
                    <div className="d-xl-flex d-lg-block text-lg-center text-md-center align-items-center m-auto px-2 p-lg-2">
                        {/* <div className={props.hideBtns ? "mr-2" : "col-md-2"} onClick={() => handleClick(u)}> */}
                        <div className={`mr-xl-2 mx-lg-0 mb-lg-1`} onClick={() => handleClick(u)}>
                            <RoundImage alt={u.name} size={props.imgsize ? props.imgsize : "35px"} src={u.profile_pic || PLACEHOLDER} />
                        </div>
                        {/* <div className={props.hideBtns ? "flex-fill" : "col-md-6"} onClick={() => handleClick(u)}> */}
                        <div className={``} onClick={() => handleClick(u)}>
                            <div className="text-truncate"> {!props.hideBtns && <Link to={`/profile/${u.id}`}>{u.name}</Link>}</div>
                            {props.hideBtns && (
                                <Link className="text-truncate" to={`/hunts/${props.huntId}/${u.application_type == "special" ? "cv" : "resume"}/${u.cv_id}`}>
                                    {u.name}
                                </Link>
                            )}
                            {!props.hideBtns && (
                                <small className="d-block text-truncate">
                                    <span className="text-muted">{u.intro}</span>
                                </small>
                            )}
                        </div>

                        {!props.hideBtns && (
                            <div className="ml-xl-auto">
                                {!u.is_following ? (
                                    <Button ref={followRefs.current[i]} type="simple" className={`${styles["aa"]} btn-sm aa border-0 px-2`} onClick={() => followUser(u, i)}>
                                        Follow
                                    </Button>
                                ) : (
                                    <Button ref={unfollowRefs.current[i]} className={`${styles["aa_active"]} ${styles["aa"]} btn-sm aa border-0 px-2`} onClick={() => followUser(u, i)}>
                                        Following
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </li>
            );
        });
    };

    return (
        <aside className="sidebar static right">
            <div className="widget">
                <h4 className="widget-title">
                    <i className="icofont-user  text-info mr-2"></i>
                    {props.heading} {props.count && <small>({props.count})</small>}
                    <Link to={seeAll} className="see-all">
                        See All
                    </Link>
                </h4>
                <ul className={`${styles["suggested-user-wgt"]} suggested-user-wgt`}>{renderList()}</ul>
            </div>
        </aside>
    );
});

export default SuggestedUsersWidget;
