import React, { useCallback, useState } from "react";
import Button from "component/Button/Button";
import { fromNow } from "../helpers/dates";
import { Link, useHistory } from "react-router-dom";
import RoundImage from "../component/Image/RoundImage";
import { clubs as clubsApi } from "../api/clubs";
import { useDispatch } from "react-redux";
import SendNotification from "../helpers/notification";
import CoverImage from "../component/Image/CoverImage";

const ClubWidget = (props) => {
    const [club, setClub] = useState(props.club);
    const dispatch = useDispatch();

    const joinClub = useCallback(() => {
        dispatch(
            clubsApi({
                param_path: `${club.id}/become_member`,
                method: "POST",
                data: {
                    notes: "ok",
                },
            }),
        )
            .then((response) => {
                if (response.success) {
                    setClub(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });

    const handleJoin = (event) => {
        joinClub();
    };

    return (
        <>
            <Link to={`/clubs/${club.id}`}>
                <div className="room-avatar">
                    <RoundImage src={club.avatar} alt={"Club Avatar"} size="70px" />
                    {/* <img src={club.avatar} alt={club.title} /> */}
                </div>
                <h6 className="d-block text-capitalize text-truncate my-2">{club.title}</h6>
            </Link>
            {!club.is_admin && (
                <>
                    {club.is_member == 0 && (
                        <a onClick={handleJoin} className="join" title="Join Club">
                            Join
                        </a>
                    )}
                    {club.is_member == 1 && (
                        <a onClick={handleJoin} className="join" title="Leave Club">
                            Leave
                        </a>
                    )}
                    {club.is_member == 0.5 && (
                        <label className="badge badge-light text-muted" disabled title="Requested">
                            Requested
                        </label>
                    )}
                </>
            )}
        </>
    );
};

export const ClubCardWidget = (props) => {
    // const club = props.club;
    const [club, setClub] = useState(props.club);
    const dispatch = useDispatch();
    const history = useHistory();

    const joinClub = useCallback(() => {
        dispatch(
            clubsApi({
                param_path: `${club.id}/become_member`,
                method: "POST",
                data: {
                    notes: "ok",
                },
            }),
        )
            .then((response) => {
                if (response.success) {
                    setClub(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });

    const handleJoin = (event) => {
        joinClub();
    };

    const postActions = [
        // {
        //     title: "Share",
        //     subText: "Share this to post friends",
        //     icon: "icofont-share-alt",
        //     action: () => {},
        // },
        {
            title: "Send Message",
            subText: "Send this in messages,groups",
            icon: "icofont-ui-text-chat",
            action: () => {},
        },
        {
            title: "Delete Post",
            subText: "If inappropriate post by mistake",
            icon: "icofont-ui-delete",
            action: () => {},
        },
        {
            title: "Report",
            subText: "Inappropriate content",
            icon: "icofont-flag",
            action: () => {},
        },
    ];

    return (
        <div className="event-post mb-3">
            <Link to={`/clubs/${club.id}`}>
                <CoverImage bgrepeat="no-repeat" bgsize="cover" bgsrc={`url(${club.avatar})`} alt={club.title} width="100%" height="130px" />
            </Link>
            {/* <figure><Link to={`/clubs/${club.id}`}>
                        <img src={club.avatar} alt={club.title} style={{width:'265px',height:'265px'}} /></Link>
                        </figure> */}
            <div className="event-meta">
                <span>{fromNow(club.created_at)}</span>
                <Link to={`/clubs/${club.id}`}>
                    <div className="row">
                        <h6 className="text-capitalize text-truncate col-8">{club.title}</h6>
                        <i className={`text-muted text-right col-4 ${club.type == "public" ? "icofont-globe-alt" : "icofont-lock"}`} />
                    </div>
                </Link>{" "}
                <p className="text-truncate">{club.summary}</p>
                {!club.is_admin && (
                    <>
                        {!club.is_member && (
                            <Button onClick={handleJoin} className="btn-block" type="button" title="Join Club">
                                Join
                            </Button>
                        )}

                        {club.is_member && club.membership_status == "active" && (
                            <Button onClick={handleJoin} className="btn-block" type="button" title="Leave Club">
                                Leave
                            </Button>
                        )}

                        {club.is_member && club.membership_status == "pending" && (
                            <Button className="btn-block" type="simple" title="Requested" disabled>
                                Requested
                            </Button>
                        )}

                        {club.is_member && club.membership_status == "blocked" && (
                            <Button className="btn-block" type="simple" disabled title="Blocked">
                                Blocked
                            </Button>
                        )}
                    </>
                )}
                {club.is_admin && (
                    <>
                        <Button type="button" onClick={() => history.replace(`/clubs/${club?.id}`)} className="classic-btn btn-block" title="Manage Club">
                            Manage
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
export const UserClubWidget = (props) => {
    const club = props.userClub;
    return (
        <>
            <li key={club.id}>
                <Link to={`/clubs/${club.id}`}>
                    <RoundImage alt="Club Avatar" src={club.avatar} size="25px" />
                    <div className="your-grp">
                        <h5 className="text-capitalize text-truncate"> {club.title}</h5>
                    </div>
                </Link>
            </li>
        </>
    );
};
// export ClubCardWidget;
export default ClubWidget;
