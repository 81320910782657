import { isMoment } from "moment";

export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  let outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const appendArray = (formData, key, arrValue) => {
  arrValue.forEach((value, index) => {
    if (isMoment(value)) {
      return formData.append(`${key}[${index}]`, value.format("YYYY-MM-DD"));
    }

    if (value instanceof Array) {
      return appendArray(formData, `${key}[${index}]`, value);
    }

    if (value instanceof File) {
      return formData.append(`${key}[${index}]`, value, value.name);
    }

    if (value !== null && typeof value === typeof {}) {
      return appendObject(formData, `${key}[${index}]`, value);
    }

    formData.append(`${key}[${index}]`, value);
  });
};

const appendObject = (formData, mainKey, obj) => {
  Object.keys(obj).forEach((key, index) => {
    const value = obj[key];
    if (isMoment(value)) {
      return formData.append(`${mainKey}[${key}]`, value.format("YYYY-MM-DD"));
    }

    if (value instanceof Array) {
      return appendArray(formData, `${mainKey}[${key}]`, value);
    }

    if (value instanceof File) {
      return formData.append(`${mainKey}[${key}]`, value, value.name);
    }

    if (value !== null && typeof value === typeof {}) {
      return appendObject(formData, `${mainKey}[${key}]`, value);
    }

    formData.append(`${mainKey}[${key}]`, value);
  });
};

export const getFormData = (obj = {}, formData) => {
  formData = formData || new FormData();
  for (const key in obj) {
    if (isMoment(obj[key])) {
      formData.append(key, obj[key].format("YYYY-MM-DD"));
      continue;
    }

    if (obj[key] instanceof Array) {
      appendArray(formData, key, obj[key]);
      continue;
    }

    if (obj[key] instanceof File) {
      formData.append(key, obj[key]);
      continue;
    }

    if (obj[key] !== null && typeof obj[key] === typeof {}) {
      appendObject(formData, key, obj[key]);
      continue;
    }

    formData.append(key, obj[key]);
  }
  return formData;
};

export const currencyFormat = (amount) => {
  return new Intl.NumberFormat().format(amount);
};

export const toggleArrayMembership = (arr, element, column = "id") => {
  let found = arr.filter((a) => element[column] == a[column]);
  if (found.length) {
    return arr.filter((a) => element[column] != a[column]);
  } else {
    return [...arr, element];
  }
};

export const isAllowedVideoFormat = (filename) => {
    let filetype = getFileType(filename);

    if (filetype) {
        return filetype == "mp4" || filetype == "webm" || filetype == "mov";
    }

    return false;
};

export const getFileType = (filename) => {
    let parts = filename.split(".");

    if (parts.length > 0) {
        return parts[parts.length - 1].toString().toLowerCase();
    }

    return false;
};
