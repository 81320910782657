import { apiCall } from "../helpers/api";

/*------------------------------------------ Create Post ----------------------------------------------------*/

export const createChallenge = (params = {}) => (dispatch) => {
    params.method = params.method || "POST";
    params.path = params.path || "challenges"
    return dispatch(apiCall(params))
}

/*--------------------------------------------------------------------------------------------------- */

export const challenges = (params = {}) => dispatch => {
    params.path = params.path || "challenges";
    // params.method = params.method || "GET";

    return dispatch(apiCall(params));
}