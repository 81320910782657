import React, {useRef, useState} from "react"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { posts } from "../../api/posts";
import BuyPost from "../../Widgets/BuyPost";
import Button from "../Button/Button";



const BuyModal = (props) => {
    const [showModal, setShowModal] = useState(props.show);
    const auth_user = useSelector((store) => store.user.user);
    const [selectedCard, setSelectedCard] = useState(auth_user?.card_details[0]?.id);
    const buyBtn = useRef();
    const dispatch = useDispatch();
    const post = props.post;
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const BuyPostModal = (post) => {
        return <BuyPost post={post} selected-card={selectedCard} set-selected-card={setSelectedCard} />;
    };

    const buyPost = () => {
        buyBtn?.current.showLoader(true);
        dispatch(
            posts({
                method: "POST",
                param_path: post.id.toString() + "/buy",
                data: {
                    card_id: selectedCard,
                },
            })
        ).then((response) => {
            if (response.success) {
                buyBtn?.current.showLoader(false);
                if (response.data.has_purchased) {
                    toast.success("Post Purchased Successfully");
                    props.update(response.data);
                    setShowModal(false);
                    props.hide();
                } else {
                    toast.error("Post Purchase Failed");
                }
            } else {
                buyBtn?.current.showLoader(false);
                toast.error(response.message);
            }
        });
    };
    
    return (
        <Modal size="lg" id="submit-modal" show={showModal} onHide={() => setShowModal(false)} backdrop="static">
            <Modal.Header className="p-3 border-bottom">
                <h4 className="modal-title">Buy Post</h4>
                <Button className=" close" type="link" onClick={() => {
                    setShowModal(false);
                    props.hide();
                }}>
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>{BuyPostModal(post)}</Modal.Body>
            <Modal.Footer>
                {auth_user?.card_details?.length > 0 ? (
                    <>
                        <Button ref={buyBtn} type="button" htmlType="button" onClick={buyPost}>
                            Buy{" "}
                        </Button>
                        <Button type="button" onClick={() => {
                            setShowModal(false);
                            props.hide();
                        }}>
                            Close
                        </Button>
                    </>
                ) : (
                    <Button type="button" className="btn btn-primary d-block" onClick={() => setShowPaymentModal(true)}>
                        Add Card
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default BuyModal;