import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="owl-controls">
            <div className="owl-nav">
                <div className="owl-next" onClick={onClick}></div>
            </div>
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="owl-controls">
            <div className="owl-nav">
                <div className="owl-prev" onClick={onClick}></div>
            </div>
        </div>
    );
};

const SlickCarousel = (props) => {
    const settings = {
        dots: props.dots || false,
        infinite: props.infinite || false,
        speed: props.speed || 500,
        slidesToShow: props.slidesToShow || 4,
        slidesToScroll: props.slidesToScroll || 1,
        className: props.customClass || '',
        responsive: props.breakpoints || [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false,
                    infinite: false,
                    speed: 500,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                    infinite: false,
                    speed: 500,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    infinite: false,
                    speed: 500,
                },
            },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return <Slider {...settings}>{props.children}</Slider>;
};

export default SlickCarousel;
