import Button from "../../component/Button/Button";
import Error from "../../component/Error/Error";
import Input from "../../component/Input/Input";
import Progress from "../../component/Progress/Progress";
import React, { useEffect, useRef, useState } from "react";
import Video from "../../component/Video/Video";
import { setErrorMessages, validateRequired } from "../../helpers/validationMessages";
import { getFormData } from "../../helpers/utility";
import { useDispatch } from "react-redux";
import { clubs as clubsApi } from "../../api/clubs";
import { toast } from "react-toastify";
import Joi from "joi";
import { posts } from "../../api/posts";
import CoverImage from "../../component/Image/CoverImage";

const CreatePost = (props) => {
    const { clubId, setClubPosts } = props;
    const [uploadedVideo, setVideo] = useState();
    const [videoSrc, setVideoSrc] = useState();
    const [errors, setErrors] = useState({});
    const openFileUpload = () => fileUploadRef.current?.click();
    const fileUploadRef = useRef();
    const formRef = useRef();
    const progressRef = useRef();
    const postBtn = useRef();
    const dispatch = useDispatch();
    const [post, setPost] = useState(props.post || {});
    const [thumbnail, setThumbnailImage] = useState();
    const [validateThumbnail, setValidateThumbnail] = useState(1);
    const [thumbnailSrc, setThumbnailSrc] = useState();

    const onThumbnailUpload = (e) => {
        setThumbnailImage(e.target.files[0]);
        setThumbnailSrc(URL.createObjectURL(e.target.files[0]));
    };

    const removeThumbnail = (e) => {
        e.preventDefault();
        setValidateThumbnail(1);
        setThumbnailImage(null);
        setThumbnailSrc(null);
    };

    useEffect(() => {
        if (props.post) {
            setVideoSrc(props.post?.uri);
            setValidateThumbnail(0);
            setThumbnailSrc(props.post.thumbnail);
        }
    }, [dispatch]);

    const onFileUpload = (e) => {
        const videoFile = e.target.files[0];

        if (videoFile.type != "video/webm" && videoFile.type != "video/mp4") {
            return setErrors({
                video: "Invalid video format",
            });
        }

        const video = document.createElement("video");
        video.src = URL.createObjectURL(videoFile);
        setVideoSrc(URL.createObjectURL(videoFile));

        video.addEventListener(
            "loadeddata",
            function () {
                if (video.videoWidth < 0) {
                    return setErrors({
                        video: "Video Width out of bound",
                    });
                }

                if (video.videoHeight < 0) {
                    return setErrors({
                        video: "Video height out of bound",
                    });
                }

                if (video.duration > 600) {
                    return setErrors({
                        video: "Video length exceeds the time limit",
                    });
                }

                return setVideo(videoFile);
            },
            false,
        );

        video.addEventListener(
            "loadedmetadata",
            function () {
                if (video.videoWidth < 0) {
                    return setErrors({
                        video: "Video Width out of bound",
                    });
                }

                if (video.videoHeight < 0) {
                    return setErrors({
                        video: "Video height out of bound",
                    });
                }

                if (video.duration > 600) {
                    return setErrors({
                        video: "Video length exceeds the time limit",
                    });
                }

                return setVideo(videoFile);
            },
            false,
        );
    };

    const onSubmitForm = async (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        let data = {
            content: formData.get("content"),
            // video: uploadedVideo,
            validate_thumbnail: validateThumbnail,
            is_public: 1,
            is_comment_allowed: 1,
            region_id: 1,
            type: "club",
        };

        if (validateThumbnail === 1) {
            data = { ...data, thumbnail };
        }

        const { error } = newPostSchema.validate(data, { abortEarly: false });

        if (error) {
            const t = setErrorMessages(error);
            console.log(t);
            return setErrors(t);
        }

        delete data.validate_thumbnail;

        const params = {
            data,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                progressRef.current?.setProgress((progressEvent.loaded / progressEvent.total) * 100);
            },
        };

        params.data._method = "PATCH";
        params.data = getFormData(params.data);

        postBtn.current.showLoader(true);
        try {
            const response = await dispatch(
                posts({
                    data: params.data,
                    param_path: `${props?.post?.id}`,
                    method: "POST",
                }),
            );

            if (response.success) {
                toast.success(response.message);
                if (props.updatePost) {
                    props.updatePost(response.data);
                }
                props.handleClose();

                // if (setClubPosts) {
                //     setClubPosts((p) => [response.data, ...p]);
                // }
            }
        } catch (e) {
            const t = setErrorMessages(e, true);

            setErrors(t);
        }
        progressRef.current?.setProgress(0);
        postBtn.current?.showLoader(false);
    };

    const uploaded = (
        <div className="col-lg-5" style={{ padding: 0, position: "relative" }}>
            <div style={{ marginTop: "1.5rem" }}>{videoSrc && <Video form src={videoSrc} style={{ width: "100%", height: "250px" }} />}</div>
        </div>
    );
    const notUploaded = (
        <div onClick={openFileUpload} className="col-lg-5 video-file" style={{ backgroundColor: "#f0f5ff", textAlign: "center" }}>
            {errors["video"] && <Error error={errors["video"]} />}
            <i className="icofont-video" style={{ color: "#1d3554", fontSize: 100 }}></i>
            <h3 style={{ color: "#1c1b1b", fontSize: 15, fontWeight: 500, marginTop: 47, marginBottom: 3 }}>Select video files to upload </h3>
            <span style={{ color: "#6e6f70", fontSize: 16 }}></span>
            <p>
                {" "}
                * MP4, WebM, or Mov
                <br />
                * recommended resolution is 1280x720 or 720x1280
                <br />* Up to 60 seconds
            </p>
            <input onChange={onFileUpload} accept=".mp4,.webm,.mp4" ref={fileUploadRef} type="file" style={{ display: "none" }} />
        </div>
    );
    return (
        <div className="row">
            {videoSrc && uploaded}
            {!videoSrc && notUploaded}
            <div className="col-lg-7">
                <div className="main-wraper">
                    <div className="feedback-form">
                        <form ref={formRef} className="mt-4" onSubmit={onSubmitForm}>
                            <fieldset className="row">
                                <div className={`mb-4 col-lg-12`}>
                                    <div className={`form-group1 mt-30`}>
                                        {thumbnail || validateThumbnail == 0 ? (
                                            <div
                                                style={{
                                                    position: "relative",
                                                }}
                                            >
                                                <Button
                                                    type="link"
                                                    style={{
                                                        position: "absolute",
                                                        right: "-6px",
                                                        fontSize: "1.5rem",
                                                        top: "-15px",
                                                    }}
                                                    onClick={removeThumbnail}
                                                >
                                                    <small
                                                        style={{
                                                            background: "#17a2b8",
                                                            color: "white",
                                                            padding: "3px",
                                                            fontSize: "10px",
                                                        }}
                                                    >
                                                        remove
                                                    </small>
                                                </Button>
                                                <CoverImage className="img-thumbnail m-auto" bgrepeat="no-repeat" bgsize="cover" bgsrc={`url(${thumbnailSrc})`} width="100%" height="250px" />
                                            </div>
                                        ) : (
                                            <>
                                                <div className={`image-upload-wrap`}>
                                                    <input onChange={onThumbnailUpload} type="file" accept="image/*" className={`file-upload-input`} />
                                                    <div className={`drag-text`}>
                                                        <i className={`icofont-cloud-upload`}></i>
                                                        <h4>Select Video banner</h4>
                                                    </div>
                                                </div>
                                                <small className="p-2 d-block">* Recommended size for video banner is 1000 x 250 pixels. Please Use a rectangular image.</small>
                                            </>
                                        )}
                                    </div>
                                    {errors["thumbnail"] && <Error marginTop="-2px" error={errors["thumbnail"]} />}
                                </div>
                                <div className="col-lg-12">
                                    <Input name="content" styleType="floating-area" placeholder="Description" defaultValue={post?.content} />
                                    {errors["content"] && <Error error={errors["content"]} />}
                                </div>

                                <div className="mb-0 col-lg-12 text-center">
                                    <Button ref={postBtn} htmlType="submit" type="button" className="form__button">
                                        Update
                                    </Button>
                                    <Progress style={{ margin: "15px" }} ref={progressRef} />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePost;

const newPostSchema = Joi.object({
    validate_thumbnail: Joi.number().required(),
    thumbnail: Joi.when("validate_thumbnail", {
        is: 1,
        then: Joi.object()
            .required()
            .messages({
                "any.required": validateRequired("Thumbnail"),
                "object.base": validateRequired("Thumbnail"),
            }),
        otherwise: Joi.any().forbidden(),
    }),
    content: Joi.string()
        .required()
        .messages({
            "string.empty": validateRequired("Description"),
        }),
    is_public: Joi.number().optional(),
    is_comment_allowed: Joi.number().optional(),
    region_id: Joi.number().optional(),
    type: Joi.string().required(),
});
