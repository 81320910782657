import React from "react";
import RoundImage from "../component/Image/RoundImage";
import { PLACEHOLDER } from "../helpers/constant";

const InterestCarousel = (props) => {
    const interest = props.interest;
    return (
        <>
            <li className="uk-active">
                <a className={`${props.isActive ? "active" : ""}`} 
                    aria-expanded={`${props.isActive ? "true" : "false"}`} 
                    onClick={props.onClick} 
                    style={{'align-items': 'center', 'display': 'flex', 'background-color': 'unset', 'padding': '0px'}}
                >
                    <span className="bg-primary d-inline-block rounded-circle p-1 mr-2">
                        <RoundImage src={interest?.image || PLACEHOLDER} size="20px" />
                    </span>
                    <p className="mb-0">{interest.title}</p>
                </a>
            </li>
        </>
    );
};

export default InterestCarousel;
