import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const MinimalCarousel = (props) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: props.desktopItemsToShow
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: props.tabletItemsToShow
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: props.mblItemsToShow
        }
    };
    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay={props.autoPlay}
            autoPlaySpeed={3000}
            centerMode={props.centerMode}
            className=""
            containerClass={`container-with-dots ${props.containerClass}`}
            dotListClass=""
            draggable
            focusOnSelect={true}
            infinite
            itemClass={props.itemClass}
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover={true}
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {props.children}
        </Carousel>
    );
};

export default MinimalCarousel;
