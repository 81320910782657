import { apiCall } from "../helpers/api";

/*------------------------------------------ Create Post ----------------------------------------------------*/

export const createClub = (params = {}) => (dispatch) => {
    params.method = params.method || "POST";
    params.path = params.path || "clubs"
    return dispatch(apiCall(params))
}


/*--------------------------------------------------------------------------------------------------- */
export const clubs = (params = {}) => dispatch => {
    params.path = params.path || "clubs";
    // params.method = params.method || "GET";

    return dispatch(apiCall(params));
}