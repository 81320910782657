import { Modal } from "react-bootstrap";
import CreatePost from "../../pages/Clubs/CreatePost";
import Button from "../Button/Button";
import React, { useState } from "react";

const ClubPostModal = (props) => {
    const { clubId, showModal, handleClose, post } = props;

    return (
        <Modal id="submit-modal" show={showModal} onHide={handleClose} backdrop="static" size="xl">
            <Modal.Header className="p-3 border-bottom">
                <h4 className="modal-title">Edit Post</h4>
                <Button className="close" type="button" onClick={handleClose}>
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <CreatePost clubId={post?.source} handleClose={handleClose} post={post} updatePost={props.updatePost} />
            </Modal.Body>
        </Modal>
    );
};

export default ClubPostModal;
