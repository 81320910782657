import React, { useEffect, useImperativeHandle, useState } from 'react';

const Progress = React.forwardRef((props, ref) => {

    const [progressPercent, setProgressPercent] = useState(0);

    useImperativeHandle(ref, () => {
        return {
            setProgress: (percent) => setProgressPercent(parseFloat(percent))
        };
    }, []);

    useEffect(() => {
        setProgressPercent((props.current / props.total) * 100);
    }, [props]);

    if(progressPercent == 0) {
        return null;
    }

    return (
        <div className="progress mt-3">
            <div className="progress-bar progress-bar-striped" role="progressbar" style={{width: `${progressPercent}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{parseFloat(progressPercent).toFixed(0)}%</div>
        </div>
    )
});

Progress.defaultProps = {
    total: 100,
    current: 0,
}

export default Progress;