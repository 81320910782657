import React from 'react';
import './Spinner.css';

const Spinner = React.memo((props) => {
    switch (props.type) {
        case "Circle":
            return (
                <div style={{ margin: "0 0.5rem" }} className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )
        case "LoadMore":
            return (
                <div className="loadmore">
                    <div className="sp sp-bars" />
                </div>
            );
        default: 
            return null;
    }
})

Spinner.defaultProps = {
    type: "PageLoader",
}

export default Spinner;