import React, { useImperativeHandle, useState } from "react";

const Collapse = React.forwardRef((props, ref) => {

    const [showChild, setChildState] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            collapse: setChildState,
        }
    }, []);

    // @Todo: Animation on Collapsible wrapper and inherit css to ensure markup doesn't break protocol
    return showChild ? props.children : null;

    // return (
    //     <div className={props.className} style={{display: showChild ? "flex": "none"}}>
    //         {props.children}
    //     </div>
    // )
});

Collapse.defaultProps = {
    className: "",
}

export default Collapse;