import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { posts as postsApi } from "../../api/posts";
import { Link } from "react-router-dom";
import RoundImage from "../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../helpers/constant";
import styles from "./ComponentCss/newsfeedchallengecard.module.css";
import Video from "../../component/Video/Video";
import SquareImage from "../../component/Image/SquareImage";
import Icon from "../../component/Icon/Icon";
import Button from "../../component/Button/Button";
const NewsFeedChallengeCard = (props) => {
    const { challenge, onDelete } = props;
    // const user = useSelector(store => store.user.user);

    const dispatch = useDispatch();

    const deleteChallenge = () => {
        // onDelete(challenge.id);
        dispatch(
            postsApi({
                method: "DELETE",
                param_path: challenge.post.id.toString(),
            }),
        )
            .then((response) => {
                if (response.success) {
                    onDelete(challenge.id);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="main-wraper p-0" style={{ position: "inherit", boxShadow: "none" }}>
            <div className="row m-auto">
                <div className="col-md-5 p-md-0 position-relative">
                    <div className={`${styles[""]} ${styles["user-post-challenge"]} user-post user-post-challenge p-5`}>
                        <div className="user-desc text-center ">
                            <Link className="d-block" to={`/profile/${challenge?.created_by?.id}`}>
                                <RoundImage className="img-thumbnail" src={challenge?.created_by?.profile_pic || PLACEHOLDER} size="40px" />
                            </Link>
                            <div className="friend-name p-0" style={{ width: "auto" }}>
                                <ins className="text-capitalize">{challenge.created_by?.name}</ins>
                                <span>@{challenge.created_by?.username}</span>
                            </div>
                        </div>
                        <hr className="center-ball-separator-hr" />
                        <div className="award-icon position-absolute" style={{ top: "10px", right: "10px" }}>
                            <i className="icofont-trophy job-clr d-block text-center" style={{ fontSize: "40px" }}></i>
                            {challenge?.status == "completed" && <span className="badge badge-success">completed</span>}
                        </div>
                        <div className="text-center">
                            <Link to={`/challenge/${challenge?.id}/accept`}>
                                <p className="mb-0  text-truncate m-auto">Challenge Title: <b style={{ fontSize: "16px" }} className="text-capitalize">{challenge.title}</b> </p>
                                <div className={`${styles["more-detail"]}`}>
                                    <p className="mb-0 ">Win Amount: <b className="rounded-pill badge-success badge " > ${challenge.win_amount} </b></p>
                                </div>
                                <p className="mb-0  m-auto text-truncate">Description: <small> {challenge.description}</small></p>
                            </Link>
                            <div className="additional-detail">
                                <p><Icon name="users text-success" className="mr-1" /> <span>{challenge.challengers_count ? challenge.challengers_count : 0} </span><small>Challenger's</small></p>
                            </div>
                            <div className="actions-btns d-flex align-items-center justify-content-center">
                                <Link to={`/challenge/${challenge?.id}/accept`} className="btn btn-sm mr-1 rounded-pill btn-light"><Icon name="long-arrow-right" className="text-success mr-1" />  View Detail</Link>
                            </div>


                            <div className="we-video-info d-none">
                                <ul>
                                    <li>
                                        <span title="Participants" className="Recommend">
                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-cursor-fill" viewBox="0 0 16 16">
                                                    <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z" />
                                                </svg>
                                            </i>
                                            <span>{challenge.challengers_count}</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-7 p-md-0">
                    <div className="vid-col">
                        {challenge.post?.uri ? <Video
                            poster={challenge.post?.thumbnail}
                            postId={challenge.post?.id} src={challenge.post?.uri} style={{ width: "100%" }} hasWatched={challenge.post?.has_viewed} /> : <SquareImage src={challenge.post?.thumbnail} width='100%' />}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default NewsFeedChallengeCard;
