import { Modal, ModalFooter } from "react-bootstrap";
import Button from "../Button/Button";
import { toast } from "react-toastify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usercvs as userCvsApi } from "../../api/cv_builder";
import { hunts as huntsApi } from "../../api/hunts";
import { useDispatch } from "react-redux";
import Input from "../Input/Input";
import { useSelector } from "react-redux";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
import Icon from "../Icon/Icon";
import { PLACEHOLDER } from "../../helpers/constant";
import { formattedDate, fromNow } from "../../helpers/dates";
import SquareImage from "../Image/SquareImage";
import styles from "./applyjobmodal.module.css";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useHistory } from "react-router";

const ApplyJobModal = (props) => {
    const { job } = props;
    const [showModal, setShowModal] = useState(false);
    const [cvPreview, setCvPreview] = useState(false);
    const [selectedCv, setSelectedCv] = useState();
    const [userCvs, setUserCvs] = useState([]);

    const [hasApplied, setHasApplied] = useState(job.has_applied);
    const [appliedCount, setAppliedCount] = useState(job.applied_by);
    const [showConfirmWithdraw, setShowConfirmWithdraw] = useState(false);
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const history = useHistory();

    const auth_user = useSelector((store) => store.user.user);

    const handleShowModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleSavedCvs = () => {
        console.log("onchange cv listing func called");
    };
    useEffect(() => {
        dispatch(userCvsApi())
            .then((response) => {
                setUserCvs(
                    response.data.map((uc) => {
                        return { ...uc, selected: false };
                    })
                );
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    const selectCv = (s) => {
        setUserCvs(
            userCvs.map((uc) => {
                if (uc.id == s.id) {
                    uc.selected = true;
                    setSelectedCv(s.id);
                } else {
                    uc.selected = false;
                }
                return uc;
            })
        );
    };

    //apply job
    const applyJob = (job_id) => {
        setErrors({});

        let data = {
            cv_id: selectedCv,
        };

        if (job_id > 0 && selectedCv == null) {
            setErrors({
                cv: "Please select a CV",
            });

            return;
        }

        console.log(job_id);
        let url = job_id < 0 ? `${job_id * -1}/unapply` : job_id + "/applicants";

        dispatch(
            huntsApi({
                param_path: url,
                method: "POST",
                data,
            })
        )
            .then((response) => {
                if (response.success) {
                    setAppliedCount(hasApplied ? appliedCount - 1 : appliedCount + 1);
                    setHasApplied((p) => !p);
                    toast.success(response.message);
                    setShowModal(false);
                } else {
                    toast.error(response.message);
                }
                setShowConfirmWithdraw(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <ConfirmModal
                centered={true}
                size={``}
                showModal={showConfirmWithdraw}
                title="Confirm"
                message={"Are you sure you want to withdraw from this job?"}
                onConfirm={() => {
                    setSelectedCv(null);
                    applyJob(job?.id * -1);
                }}
                onClose={() => setShowConfirmWithdraw(false)}
            />

            {!hasApplied && (
                <a onClick={() => handleShowModal()} className={`btn btn-sm mr-1 rounded-pill btn-light`}>
                    <i className="icofont-briefcase text-info" /> Apply
                </a>
            )}

            {hasApplied && job?.status !== "close" && (
                <a className="btn btn-sm mr-1 rounded-pill btn-light" onClick={() => setShowConfirmWithdraw(true)}>
                    <i className="icofont-logout text-danger" /> Withdraw
                </a>
            )}

            <Modal id="submit-modal" show={showModal} onHide={handleCloseModal} backdrop="static" size="" centered={true}>
                <Modal.Header className="p-3 border-bottom">
                    <h4 className="modal-title">Apply to {job.job_title}</h4>
                    <Button className="close" type="button" onClick={handleCloseModal}>
                        x
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {errors.cv && <small className="text-danger">* Please select a CV</small>}
                    {userCvs?.length > 0
                        ? userCvs?.map((sc, i) => {
                              return (
                                  <div key={i} className={`row align-items-center`} onClick={() => selectCv(sc)}>
                                      <div className="col-md-8">
                                          <div
                                              className={`resume-card d-flex align-items-center bg-light m-2 cursor-pointer p-2 shadow-sm rounded-sm  ${sc.selected == true ? `border border-info` : `border border-light`} ${
                                                  styles[`${sc.selected == true ? `selected_cv` : ``}`]
                                              }`}
                                          >
                                              <SquareImage className={`mr-2`} src={auth_user?.profile_pic || PLACEHOLDER} alt="resume-image" width={"60px"} height={"60px"} />
                                              <div className="resume-detail p-2 flex-fill">
                                                  <div className="resume-title d-flex">
                                                      <h5>{sc.title}</h5>
                                                  </div>
                                                  <div className="resume-short-desc">
                                                      <div className="s-item">
                                                          <small>
                                                              <span>{fromNow(sc.created_at)}</span>
                                                          </small>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-md-4">
                                          <Button
                                              className={`btn-sm`}
                                              type={`button`}
                                              onClick={() => {
                                                  history.push(`/${sc?.type == "classic" ? "my-resume" : "my-cv"}/${sc?.id}`);
                                              }}
                                          >
                                              Preview <Icon name={`eye-alt`} className={`ml-1 text-white`} />
                                          </Button>
                                      </div>
                                  </div>
                              );
                          })
                        : "Please Create a CV in your profile"}
                </Modal.Body>
                <ModalFooter>
                    {!hasApplied && (
                        <a onClick={() => applyJob(job?.id)} className="btn btn-sm mr-1 rounded-pill btn-light">
                            <i className="icofont-briefcase text-info" /> Apply
                        </a>
                    )}

                    {hasApplied && job?.status !== "close" && (
                        <a className="btn btn-sm mr-1 rounded-pill btn-light" onClick={() => setShowConfirmWithdraw(true)}>
                            <i className="icofont-logout text-danger" /> Withdraw
                        </a>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ApplyJobModal;
