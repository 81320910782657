import React, { useRef, useState } from "react";
import Button from "../component/Button/Button";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import PaymentMethod from "./PaymentMethod";

const BuyPost = React.memo((props) => {
    const auth_user = useSelector((store) => store.user.user);
    const {
        ["selected_card"]: selectedCard,
        ["set-selected-card"]: setSelectedCard,
    } = props;
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <form>
                <div className={`row m-auto`}>
                    <div className={`col-lg-12`}>
                        {auth_user?.card_details?.length > 0 ? (
                            <>
                                <p>
                                    This post costs ${props?.post?.price}. Are
                                    you ready to pay?
                                </p>
                                <label className="form-label">
                                    Select a Card for Payment
                                </label>
                                <select
                                    className="form-control"
                                    name="selected_card"
                                    defaultValue={selectedCard}
                                    onChange={(e) =>
                                        setSelectedCard(e.target.value)
                                    }
                                >
                                    {auth_user?.card_details?.map((c) => (
                                        <option key={c.id} value={c.id}>
                                            {c.number} - {c.name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        ) : (
                            <>
                                <label className="form-label">
                                    No Card Found
                                </label>
                            </>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
});

export default BuyPost;
