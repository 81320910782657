import React, { useEffect, useState, useCallback, useMemo } from "react";
import { fromNow } from "../helpers/dates";
import Button from "component/Button/Button";
import CoverImage from "../component/Image/CoverImage.jsx";
import RoundImage from "../component/Image/RoundImage";
import { PLACEHOLDER } from "../helpers/constant";
import { hunts as ApplyJob } from "../api/hunts";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SendNotification from "../helpers/notification";
import styles from "../pages/Home/home.module.css";
import ConfirmModal from "../component/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import ApplyJobModal from "../component/ApplyJob/ApplyJobModal";

const HuntsWidget = React.memo((props) => {
    const { hunts } = props;
    const renderList = () => {
        return hunts?.map((h) => {
            return (
                <li key={h.id}>
                    <Link to={`/hunt/${h.id}`}>
                        <RoundImage src={h.image || PLACEHOLDER} alt="Hunt Thumbnail" size="40px" />
                        <div className="position-meta">
                            <h6 className="text-capitalize text-truncate" title={h.job_title}>
                                {h.job_title}
                            </h6>
                            <small className="d-block text-muted text-capitalize text-truncate">
                                {h.job_description}
                            </small>
                        </div>
                    </Link>
                </li>
            );
        });
    };

    return (
        <aside className="sidebar">
            <div className="widget">
                <h4 className="widget-title">
                    <i className="icofont-briefcase-2 text-danger mr-2"></i>
                    {props.heading}{" "}
                    <Link to={`/all/hunts`} className="see-all">
                        See All
                    </Link>
                </h4>
                <ul className={`${styles["company-posts"]} company-posts`}>{renderList()}</ul>
            </div>
        </aside>
    );
});

export const HuntsCardWidget = (props) => {
    const hunt = props.hunt;
    const [hasApplied, setHasApplied] = useState(hunt.has_applied);
    const dispatch = useDispatch();
    const history = useHistory();
    const auth_user = useSelector((store) => store.user.user);
    const [showConfirm, setShowConfirm] = useState(false);

    // apply job
    const applyJob = useCallback(() => {
        if (hasApplied) {
            setShowConfirm(true);
            return;
        }
        dispatch(
            ApplyJob({
                param_path: hunt.id + "/applicants",
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    toast.success(response.message);
                    setHasApplied(true);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });

    const unApply = useCallback(() => {
        dispatch(
            ApplyJob({
                param_path: hunt.id + "/unapply",
                method: "POST",
            }),
        )
            .then((response) => {
                if (response.success) {
                    toast.success(response.message);

                    setHasApplied(false);
                    setShowConfirm(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    });

    const postActions = [
        // {
        //     title: "Share",
        //     subText: "Share this to post friends",
        //     icon: "icofont-share-alt",
        //     action: () => { },
        // },
        {
            title: "Send Message",
            subText: "Send this in messages,groups",
            icon: "icofont-ui-text-chat",
            action: () => { },
        },
        {
            title: "Delete Post",
            subText: "If inappropriate post by mistake",
            icon: "icofont-ui-delete",
            action: () => { },
        },
        {
            title: "Report",
            subText: "Inappropriate content",
            icon: "icofont-flag",
            action: () => { },
        },
    ];

    return (
        <>
            <ConfirmModal
                showModal={showConfirm}
                title={"Confirm"}
                message={`Are you sure you want to withdraw your application from '${hunt.job_title}'?`}
                onClose={() => setShowConfirm(false)}
                onConfirm={unApply}
            />
            <div className="event-post mb-3 flex-fill">
                <Link to={`/hunt/${hunt.id}`} title={hunt?.job_title}>
                    <CoverImage bgrepeat="no-repeat" bgsize="cover" bgsrc={`url(${hunt.image})`} alt={hunt.job_title} width="100%" height="130px" />
                </Link>
                <div className="event-meta">
                    <span>{hunt.job_type.type_name}</span>
                    <Link to={`/hunt/${hunt.id}`} title={hunt?.job_title}>
                        <h6 className="font-weight-bold text-truncate text-capitalize">{hunt.job_title}</h6>
                        <span>
                            USD:{" "}
                            <i>
                                {hunt.min_salary} - {hunt.max_salary}
                            </i>
                        </span>
                        <p className="text-truncate">{hunt.job_description}</p>
                    </Link>
                    {auth_user?.id != hunt.created_by.id && (
                        <ApplyJobModal job={hunt} />
                        // <Button type="button" className={`form__button btn-block`} onClick={() => applyJob()}>
                        //     {hasApplied ? "Withdraw" : "Apply"}
                        // </Button>
                    )}
                    {auth_user?.id == hunt.created_by.id && (
                        <Button type="button" className={`form__button btn-block`} onClick={() => history.push(`/hunt/${hunt.id}`)}>
                            Manage
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default HuntsWidget;
