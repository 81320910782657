import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button/Button";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const UnSafePostModal = (props) => {
    const history = useHistory();
    const { showModal, onConfirm, title, message, onClose } = props;
    const btnYes = useRef();
    const post = props.post;
    const auth_user = useSelector((store) => store.user.user);

    return (
        <Modal centered={true} size="md" id="submit-modal" show={showModal} onHide={() => onClose()} backdrop="static">
            <Modal.Header className="p-3 border-bottom">
                <h4 className="modal-title">{title}</h4>
                <Button className="close" type="link" onClick={() => onClose()}>
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <div className="img-section">
                        <img src="/public/images/resources/danger.png" alt="wrning" style={{ width: "50px", height: "50px" }} />
                    </div>
                    <div className="content-sec">
                        <div className="heading-sec my-2">
                            <h3>Content Moderation</h3>
                        </div>
                        <div className="content">
                            <p>Your post becomes unsafe if it is inappropriate, offensive, or unwanted. We believe in creating a safer user experience, providing brand safety assurance to advertisers, and complying with local and global regulations.</p>
                            <p>Our application uses a moderator to detect inappropriate or offensive content in images. For example, embodiments of a suggestive nature might be acceptable, but photos containing nudity might not.</p>
                            <ul style={{ padding: "0", listStyle: "none" }}>
                                <li>
                                    Harmful or dangerous content.
                                </li>
                                <li>
                                    Violent or graphic content policies.
                                </li>
                                <li>
                                    Violent criminal organizations policy.
                                </li>
                                <li>
                                    Hate speech.
                                </li>
                                <li>
                                    Harassment & cyber bullying.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div className="dflex align-items-center flex-end">

                    <Button type="button" className={`btn-sm btn-info mr-2`} onClick={() => history.replace(`/post/${post.id}/edit`)}>Edit Post</Button>

                    <Button type="button" className={`btn-sm btn-danger`} onClick={() => onClose()}>Cancel</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default UnSafePostModal;
