import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { posts as postApi } from "../../api/posts";
import { apiCall } from "../../helpers/api";
import Button from "../Button/Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ShareModal = (props) => {
    const { show, handleClose, post, source, is_boosted } = props;
    const dispatch = useDispatch();

    const platforms = ["Facebook", "Twitter", "Pinterest", "Linkedin", "Whatsapp", "Other"];
    const [selectedPlatform, setPlatform] = useState("Other");
    const [copyStatus, setCopyStatus] = useState(''); // State for copy status
    const baseUrl = process.env.RAZZLE_SITE_URL;

    const copyToClipboard = () => {
        const input = document.querySelector('.form-control'); // select input value
        input.select(); 

        if (document.execCommand("copy")) {
            setCopyStatus('Copied!'); // Update copy status
            share("Other")
            setTimeout(() => {
                setCopyStatus(''); // Reset the copy status after a few seconds
            }, 3000);
        }
    };
    
    const share = (currentPlatform = selectedPlatform) => {
        dispatch(postApi({ param_path: `${post.id}/share`, method: "POST", data: { platform: currentPlatform, source, is_boosted } }))
            .then((response) => {
                if (response.success) {
                    // shareToPlatform(response.data);
                    post.no_shares += 1;
                } else {
                    toast.error("Something Went Wrong");
                }
            })
            .catch((e) => {
                toast.error("Internal Server Error");
            });
    };

    const shareToPlatform = (share) => {
        let sharelink = `${baseUrl}post/${post.id}?source=${share.id}`;

        switch (selectedPlatform) {
            case "Facebook":
                window.FB.ui(
                    {
                        method: "share",
                        href: sharelink,
                    },
                    (response) => {
                        if (!response || response?.error_message) {
                            handleClose();
                            dispatch(
                                apiCall({
                                    path: `shares/${share.id}/delete`,
                                    method: "POST",
                                }),
                            )
                                .then((response) => {
                                    if (response.success) {
                                        console.log(response)
                                    }
                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                        }
                    },
                );
                return;
        }
    };

    return (
        <Modal size="lg" id="submit-modal" show={show} onHide={handleClose}>
            <Modal.Header className="p-3 border-bottom">
                <h4 className="modal-title">Share Post</h4>
                <Button className="close" type="link" onClick={handleClose}>
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                {/* <label>Select Platform</label>
    <select className="form-control mt-1" defaultValue="Facebook" onChange={(e) => setPlatform(e.target.value)}>
        {platforms.map((p, i) => (
            <option key={i} value={p}>
                {p}
            </option>
        ))}
    </select>
    <Button type="button" className="btn btn-primary d-block mt-3 float-right" onClick={share}>
        Share
    </Button> */}
                <div className="d-flex justify-content-center">
                    <div className="rounded h2 p-3">
                        <a href={`https://www.facebook.com/sharer.php?u=${baseUrl}post/${post.id}`} rel="noreferrer" target="_blank" onClick={() => { setPlatform("Facebook"); share("Facebook"); } }>
                            <i className="icofont-facebook text-lg"></i>
                        </a>
                    </div>
                    <div className="rounded h2 p-3">
                        <a href={`https://twitter.com/share?text=reverieTalent&url=${baseUrl}post/${post.id}&via=reverie`} rel="noreferrer" target="_blank" onClick={() => { setPlatform("Twitter"); share("Twitter"); } }> 
                            <i className="icofont-twitter"></i>
                        </a>
                    </div>
                    <div className="rounded h2 p-3">
                        <a href={`https://pinterest.com/pin/create/button/?url=${baseUrl}post/${post.id}&description=reverieTalent`} rel="noreferrer" target="_blank" onClick={() => { setPlatform("Pinterest"); share("Pinterest"); } } > 
                            <i className="icofont-pinterest"></i>
                        </a>
                    </div>
                    <div className="rounded h2 p-3">
                        <a href={`https://www.linkedin.com/shareArticle?url=${baseUrl}post/${post.id}&title=reverieTalent`} rel="noreferrer" target="_blank" onClick={() => { setPlatform("Linkedin"); share("Linkedin"); } } > 
                            <i className="icofont-linkedin"></i>
                        </a>
                    </div>
                    <div className="rounded h2 p-3">
                        <a href={`https://api.whatsapp.com/send?text=Check out the reverie talent using ${baseUrl}post/${post.id}`} rel="noreferrer" target="_blank" onClick={() => { setPlatform("Whatsapp"); share("Whatsapp"); } } > 
                            <i className="icofont-whatsapp"></i>
                        </a>
                    </div>
                </div>
                <p className="text-center">or copy link</p>
                <div className="d-flex justify-content-center">
                    <div className="input-group w-50">
                        <span className="input-group-text" id="addon-wrapping"><i className="icofont-link"></i></span>
                        <input className="form-control" type="text" readOnly value={`${baseUrl}post/${post.id}`} />
                        <button className="btn btn-info" onClick={copyToClipboard}>Copy</button>
                    </div>
                </div>
                <p className="m-2 text-success text-center">{copyStatus}</p>
            </Modal.Body>
        </Modal>
    );
};

export default ShareModal;
