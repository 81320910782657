import { apiCall } from "../helpers/api";

/*------------------------------------------ Create Post ----------------------------------------------------*/

export const createUserPost = (params = {}) => (dispatch) => {
    params.method = params.method || "POST";
    params.path = params.path || "posts"
    return dispatch(apiCall(params))
}

/*--------------------------------------------------------------------------------------------------- */

export const posts = (params = {}) => dispatch => {
    params.path = params.path || "posts";
    // params.method = params.method || "GET";

    return dispatch(apiCall(params));
}
