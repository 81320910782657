import React from "react";
import { ModuleFilenameHelpers } from "webpack";
import UserPost from "../../Widgets/Posts/UserPost";
import NewsFeedChallengeCard from "./NewsFeedChallengeCard";
// import UserChallengeCard from "./UserChallengeCard";
import UserJobCard from "./UserJobCard";

const NewsFeedItem = (props) => {
    const renderUserPost = () => {
        return <UserPost key={props.item.id} post={props.item} updatePost={props.updatePost} deletePost={props.deletePost} />;
    };

    const renderChallengePost = () => {
        // return <UserChallengeCard challenge={props.item} onDelete={props.onDelete} />
        return <NewsFeedChallengeCard challenge={props.item} onDelete={props.onDelete} />;
    };

    const renderHuntPost = () => {
        return <UserJobCard job={props.item} onDelete={props.deletePost} />;
    };

    const renderClubPost = () => {
        return <UserPost key={props.item.id} post={props.item} updatePost={props.updatePost} deletePost={props.deletePost} club={props.item.club} />;
    };
    return (
        <React.Fragment>
            {props.type == "user" && renderUserPost()}
            {props.type == "job" && renderHuntPost()}
            {props.type == "challenge" && renderChallengePost()}
            {props.type == "club" && renderClubPost()}
        </React.Fragment>
    );
};

export default NewsFeedItem;
