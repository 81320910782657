import React, { useRef, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Video from "component/Video/Video";
import Button from "component/Button/Button";
import { PLACEHOLDER } from "helpers/constant";
import Collapse from "../../component/Collapse/Collapse";
import { useLocation, useHistory, Link } from "react-router-dom";
import { posts as postsApi } from "../../api/posts";
import { useDispatch } from "react-redux";
import { comments } from "../../api/comment";
import { fromNow } from "../../helpers/dates";
import { useSelector } from "react-redux";
import Icon from "../../component/Icon/Icon";
import RoundImage from "../../component/Image/RoundImage";
// import PostStats from "./PostStats";
// import PostReaction from "./PostReaction";
import PostStats, {PostReaction} from "./PostReactionStats";
import PostComments from "./PostComments";
import ShareModal from "../../component/ShareModal/ShareModal";
import ClubPostModal from "../../component/ClubPostModal/ClubPostModal";
import styles from "./ComponentCss/newsfeedcard.module.css";
import SquareImage from "../../component/Image/SquareImage";
import UnSafePostModal from "../../component/UnsafePostModal/UnsafePostModal";
import ReportButton from "../../component/ReportButton/ReportButton";

const UserPost = (props) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [commentText, setCommentText] = useState("");
    const likeBtn = useRef();
    const submittingCommentRef = useRef();
    const auth_user = useSelector((store) => store.user.user);
    const [showComments, setShowComments] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showClubPostModal, setShowClubPostModal] = useState(false);
    const [showUnSafePostModal, setShowUnSafePostModal] = useState(false);

    let postActions = [
        // {
        //     title: "Share",
        //     subText: "Share this on Facebook",
        //     icon: "flag",
        //     action: () => setShowShareModal(true),
        // },
        // {
        //   title: "Boost Post",
        //   subText: "Boost post for more engagement",
        //   icon: "paper-plane",
        //   action: () => {
        //   }
        // },
        // {
        //   title: "Edit Post",
        //   subText: "Edit This Post within a Hour",
        //   icon: "pen-alt-1",
        //   action: () => {
        //   }
        // },
        // {
        //   title: "Hide Post",
        //   subText: "Hide This Post",
        //   icon: "ban",
        //   action: () => {
        //   }
        // },
        // {
        //   title: "Report",
        //   subText: "Inappropriate content",
        //   icon: "flag",
        //   action: () => {
        //   }
        // },
    ];
    const [mentions, setMentions] = useState(props.post?.mentions);

    if (props.post?.created_by?.id == auth_user.id) {
        postActions.push({
            title: "Edit Post",
            type: "object",
            subText: "Edit This Post within a Hour",
            icon: "pen-alt-1",
            action: () => {
                if (props.post?.type == "user") {
                    history.push(`/post/${props.post?.id}/edit`);
                }

                if (props.post?.type == "club") {
                    setShowClubPostModal(true);
                }
            },
        });
        postActions.push({
            title: "Delete Post",
            type: "object",
            subText: "If inappropriate Post By Mistake",
            icon: "ui-delete",
            action: async (e, { id }) => {
                e.stopPropagation();
                likeBtn.current?.setLoader(true);

                try {
                    const params = {
                        param_path: id.toString(),
                        method: "DELETE",
                    };

                    const response = await dispatch(postsApi(params));
                    if (response.success) {
                        // dispatch(deletePost(id));
                        props.deletePost(id);
                    }
                } catch (err) {
                    console.log(err);
                }

                likeBtn.current?.setLoader(false);
            },
        });
    }

    if (props.post?.created_by?.id != auth_user.id) {
        postActions.push({
            type: "button",
            btn: <ReportButton postId={props.post.id} />
        });
    }

    const formRef = useRef();
    const commentCollapse = useRef();

    const onComment = async (e) => {
        e.preventDefault();
        submittingCommentRef.current?.setLoader(true);
        try {
            const params = {
                method: "POST",
                params: {
                    post_id: props.post?.id,
                    comment: commentText,
                },
            };
            const response = await dispatch(comments(params));
            if (response.success) {
                props.updatePost(response.data);
                // dispatch(updatePost(response.data));
                setCommentText("");
            }
        } catch (err) {
            console.log(err);
        }
        submittingCommentRef.current?.setLoader(false);
    };

    const onLike = async (e) => {
        e.preventDefault();
        likeBtn.current?.setLoader(true);
        try {
            const params = {
                method: "POST",
                param_path: `${props.post.id}/like`,
            };

            const response = await dispatch(postsApi(params));

            if (response.success) {
                props.updatePost(response.data);
                // dispatch(updatePost(response.data));
            }
        } catch (err) {
            console.log(err);
        }

        likeBtn.current?.setLoader(false);
    };

    const navigateToDetail = (e) => {
        e.stopPropagation();
        history.push(`/post/${props.post?.id}`);
    };

    const toggleComments = () => {
        setShowComments((p) => !p);
    };

    console.log(props);
    return (
        <div className={`main-wraper p-0`} style={{ position: "inherit", boxShadow: "unset", border: "unset" }}>
            <ShareModal post={props.post} show={showShareModal} handleClose={() => setShowShareModal(false)} />
            <ClubPostModal post={props.post} showModal={showClubPostModal} handleClose={() => setShowClubPostModal(false)} updatePost={props.updatePost} />
            <UnSafePostModal post={props.post} showModal={showUnSafePostModal} onHide={() => setShowUnSafePostModal(false)} onClose={() => setShowUnSafePostModal(false)} backdrop="static" />

            <div className={`row m-0`}>
                <div className={`col-lg-7 p-0 d-flex flex-column justify-content-center`}>
                    <div className={`text-center`} style={{ background: "#000000", borderRadius: "15px", overflow: "hidden" }}>
                        {props.post?.uri ? (
                            <Video
                                poster={props.post?.thumbnail}
                                className={`${!props.post?.uri ? "blur-post premium-post" : ""}`}
                                postId={props.post?.id}
                                src={props.post?.uri}
                                // style={{ width: "100%" }}
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                hasWatched={props.post?.has_viewed}
                            />
                        ) : (
                            <SquareImage src={props.post?.thumbnail} width="100%" />
                        )}
                        <div className="position-absolute" style={{ top: "10px", left: "10px" }}>
                            {!props.post?.uri && <span className="badge badge-warning float-left mr-2">Premium</span>}
                            {props.post?.status == "unsafe" && (
                                <span className="badge badge-danger float-left cursor-pointer" onClick={() => setShowUnSafePostModal(true)}>
                                    <Icon name={`info-circle`} className={`mr-1`} />
                                    Unsafe
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`col-lg-5 px-0 position-relative py-2`}>
                    <div className={`row m-0`}>
                        <div className={`col`}>
                            <div className={`more `}>
                                {postActions.length > 0 && (
                                    <div className={`more-post-optns`}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className={`feather feather-more-horizontal`}
                                        >
                                            <circle cx={12} cy={12} r={1} />
                                            <circle cx={19} cy={12} r={1} />
                                            <circle cx={5} cy={12} r={1} />
                                        </svg>
                                        <ul
                                            className="p-0 pt-1 mb-0 d-flex justify-content-between"
                                            style={{fontSize: "18px"}}
                                        >
                                            {postActions.map((a, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {postActions.length > 0 && (
                                                            <li key={`${a.icon}-${index}`}  className="d-inline">
                                                                {a?.type === "object" && (
                                                                <Button
                                                                    onClick={(e) =>
                                                                        a.action(e, {
                                                                            id: props.post?.id,
                                                                        })
                                                                    }
                                                                    type="button"
                                                                    className="btn btn-sm mr-1 rounded-pill d-flex"
                                                                >
                                                                    <Icon name={a.icon} className="m-auto"/> {a.title}
                                                                    {/* <span>{a.subText}</span> */}
                                                                </Button>
                                                                )}
                                                                {a?.type === "button" && (
                                                                    a.btn
                                                                )}
                                                            </li>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className={`d-flex align-items-start`}>
                                <div className={`user-img align-self-center`}>
                                    <RoundImage
                                        size="45px"
                                        src={(props.post?.nature == "club" ? props.post?.club?.avatar : props.post?.created_by?.profile_pic) || PLACEHOLDER}
                                        alt={props.post?.nature == "club" ? props.post?.club?.name : props.post?.created_by?.name}
                                    />
                                </div>
                                <div className={`user-info ml-2 align-self-center text-truncate flex-fill`}>
                                    <Link className={`text-capitalize text-info`} to={props.post?.nature == "club" ? `/clubs/${props.post?.club?.id}` : `/profile/${props.post?.created_by?.id}`}>
                                        {props.post?.nature == "club" ? props.post?.club?.title : props.post?.created_by?.name}
                                    </Link>
                                    {props.club && !props?.inClub && props?.post?.nature != "club" && (
                                        <>
                                            <span className="mx-1 font-italic">via</span>
                                            <Link className={`text-truncate`} to={`/clubs/${props.club?.id}`}>
                                                "{props.club?.title}"
                                            </Link>
                                        </>
                                    )}
                                    {props.post.mentions.length > 0 && (
                                        <span className="ml-1">
                                            is with{" "}
                                            <small className="mr-1">
                                                <Link className={`text-capitalize text-info`} to={`/profile/${mentions[0]?.id}`}>
                                                    {mentions[0]?.name}
                                                </Link>
                                            </small>
                                            {mentions.length > 1 && (
                                                <>
                                                    <small>&</small>{" "}
                                                    <small id={props.post.id} className={`cursor-pointer`}>
                                                        {mentions.length - 1} other(s)
                                                    </small>
                                                    <ReactTooltip anchorId={props.post.id} place="right" variant="light" className="border" float={true} clickable={true}>
                                                        {mentions?.slice(1).map((tu, i) => {
                                                            return (
                                                                <small key={i} className="tag-user ml-1 d-block">
                                                                    <Link className={`text-capitalize text-info`} to={`/profile/${tu.id}`}>
                                                                        {tu.name}
                                                                    </Link>
                                                                </small>
                                                            );
                                                        })}
                                                    </ReactTooltip>
                                                </>
                                            )}
                                        </span>
                                    )}

                                    <div className={`d-flex`}>
                                        <div className={`${styles["post-time-desc"]} d-block mr-auto`}>
                                            <span>
                                                {props.club ? <i className={`icofont-${props.club?.type == "public" ? "globe" : "lock"} mr-1`} /> : <i className={`icofont-${props.post?.is_public == 1 ? "globe" : "lock"} mr-1`} />}
                                                {fromNow(props.post.created_at)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={`${styles["post-time-desc"]}  d-none`}>
                                        <span>
                                            {props.club ? <i className={`icofont-${props.club?.type == "public" ? "globe" : "lock"} mr-1`} /> : <i className={`icofont-${props.post?.is_public == 1 ? "globe" : "lock"} mr-1`} />}
                                            {fromNow(props.post.created_at)}
                                        </span>
                                    </div>
                                </div>
                                <div className={`user-info ml-2 align-self-center flex-fill d-none`}>
                                    <Link className={`text-capitalize`} to={`/profile/${props.post?.created_by?.id}`}>
                                        {props.post?.created_by?.name}
                                    </Link>
                                    <div className={`d-block`}>
                                        <ins>
                                            {props.club && (
                                                <span>
                                                    {" "}
                                                    via{" "}
                                                    <Link className={`text-truncate`} to={`/clubs/${props.club?.id}`}>
                                                        {props.club?.title}
                                                    </Link>
                                                </span>
                                            )}
                                        </ins>

                                        {postActions.length > 0 && (
                                            <div className={`more`}>
                                                <div className={`more-post-optns`}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className={`feather feather-more-horizontal`}
                                                    >
                                                        <circle cx={12} cy={12} r={1} />
                                                        <circle cx={19} cy={12} r={1} />
                                                        <circle cx={5} cy={12} r={1} />
                                                    </svg>
                                                    <ul>
                                                        {postActions.map((a, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {postActions.length > 0 && (
                                                                        <li key={`${a.icon}-${index}`}>
                                                                            {a?.type === "object" && (
                                                                            <Button
                                                                                onClick={(e) =>
                                                                                    a.action(e, {
                                                                                        id: props.post?.id,
                                                                                    })
                                                                                }
                                                                                type="link"
                                                                                style={{
                                                                                    textAlign: "left",
                                                                                }}
                                                                            >
                                                                                <Icon name={a.icon} /> {a.title}
                                                                                {/* <span>{a.subText}</span> */}
                                                                            </Button>
                                                                            )}
                                                                            {a?.type === "button" && (
                                                                                a.btn
                                                                            )}
                                                                        </li>
                                                                    )}
                                                                    
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`${styles["post-time-desc"]} d-block`}>
                                        <span>
                                            {props.club ? <i className={`icofont-${props.club?.type == "public" ? "globe" : "lock"} mr-1`} /> : <i className={`icofont-${props.post?.is_public == 1 ? "globe" : "lock"} mr-1`} />}
                                            {fromNow(props.post.created_at)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`row m-0 post-desc-sec mt-4`}>
                        <div className={`col`}>
                            <p onClick={navigateToDetail} className={`my-2 text-truncate`} style={{ cursor: "pointer" }}>
                                {props.post?.content}
                            </p>
                        </div>
                    </div>
                    <div className={`${styles["post-engagement-sec"]} row m-0 `}>
                        <div className={`col`}>
                            <PostStats post={props.post} updatePost={props.updatePost} toggleComments={toggleComments}/>
                            <PostReaction updatePost={props.updatePost} post={props.post} toggleComments={toggleComments} />
                        </div>
                    </div>
                </div>
            </div>
            <PostComments comments={props.post.comments} show={showComments} updatePost={props.updatePost} post_id={props.post.id} />
        </div>
    );
};

export default UserPost;
