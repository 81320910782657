import React, { useCallback, useEffect, useRef, useState } from "react";
import Icon from "../../component/Icon/Icon";
import { comments as commentsApi } from "../../api/comment";
import { useDispatch, useSelector } from "react-redux";
import { fromNow } from "../../helpers/dates";
import RoundImage from "../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../helpers/constant";
import { posts } from "../../api/posts";
import ConfirmModal from "../../component/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const PostComments = (props) => {
    const { post_id } = props;
    const [comments, setComments] = useState([]);
    const [pagination, setPagination] = useState({
        last_page: props.no_comments > 5 ? 2 : 1,
    });

    const dispatch = useDispatch();

    const submittingCommentRef = useRef();
    const [commentText, setCommentText] = useState("");

    const getPage = (per_page = 10, page_no = 1) => {
        let params = {
            per_page,
        };

        if (comments.length > 0) {
            params.load = comments[comments.length - 1].id;
        }

        dispatch(
            posts({
                param_path: `${post_id}/comments`,
                params,
            }),
        ).then((response) => {
            if (response) {
                setComments([...comments, ...response.data]);
                setPagination(response.pagination_data);
            }
        });
    };

    const loadMoreComments = (e) => {
        e.preventDefault();

        getPage();
    };

    useEffect(() => {
        getPage();
    }, [dispatch]);

    const onComment = async (e) => {
        e.preventDefault();
        if (!commentText) {
            return;
        }
        submittingCommentRef.current?.setLoader(true);
        try {
            const params = {
                method: "POST",
                params: {
                    post_id: props.post_id,
                    comment: commentText,
                },
            };
            const response = await dispatch(commentsApi(params));
            if (response.success) {
                setComments([response.data.comments[0]].concat(comments));
                props.updatePost(response.data);
                setCommentText("");
            }
        } catch (err) {
            console.log(err);
        }
        submittingCommentRef.current?.setLoader(false);
    };

    const deleteComment = (id) => {
        dispatch(
            commentsApi({
                param_path: `${id}`,
                method: "POST",
                data: {
                    _method: "DELETE",
                },
            }),
        )
            .then((response) => {
                if (response.success) {
                    setComments(comments.filter((comment) => comment.id !== id));
                    props.updatePost(response.data);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <div>
            {props.show && (
                <div className="row m-0">
                    <div className="col-lg-12">
                        <div className="new-comment" style={{ display: "block" }}>
                            <form method="post" onSubmit={onComment}>
                                <input value={commentText} onChange={(e) => setCommentText(e.target.value)} type="text" placeholder="write something" />
                                <button type="submit">
                                    <Icon name="paper-plane" ref={submittingCommentRef} />
                                </button>
                            </form>
                            <div className="comments-area">
                                <ul>
                                    {comments.map((comment) => {
                                        return (
                                            <li key={comment.id} style={{ display: "flex" }}>
                                                <Comment comment={comment} deleteComment={deleteComment} />
                                            </li>
                                        );
                                    })}
                                </ul>
                                {pagination.last_page > 1 && (
                                    <div className="p-3 bg-light text-center mb-3" onClick={loadMoreComments}>
                                        Load More Comments
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PostComments;

const Comment = (props) => {
    const { comment, deleteComment } = props;
    const auth_user = useSelector((store) => store.user.user);
    const [action, setAction] = useState("view");
    const [showConfirm, setShowConfirm] = useState(false);

    return (
        <>
            <ConfirmModal
                showModal={showConfirm}
                title={"Confirm"}
                message={`Are you sure you want to delete this comment?`}
                onClose={() => setShowConfirm(false)}
                onConfirm={() => {
                    props.deleteComment(comment.id);
                    setShowConfirm(false);
                }}
            />
            <Link key={comment?.user?.id} to={`/profile/${comment?.user?.id}`} title={comment?.user?.name}>
                <RoundImage src={comment?.user?.profile_pic || PLACEHOLDER} alt={comment?.user?.name} size="35px" />
            </Link>
            <div className="commenter p-0" style={{ width: "93%" }}>
                <h5>
                    <Link key={comment?.user?.id} to={`/profile/${comment?.user?.id}`} title={comment?.user?.name}>
                        {comment?.user?.name}
                    </Link>
                </h5>
                <span>{fromNow(comment?.created_at)}</span>
                {auth_user && comment?.user?.id == auth_user.id && <i className="icofont-close float-right" onClick={() => setShowConfirm(true)} />}
                <p>{comment?.comment}</p>
            </div>
        </>
    );
};
